<template>
  <div class="container_pay">
    <clone-tab :bgcShow="bgcShow"></clone-tab>

    <div class="header_box">
      <div>工分数量</div>
      <div class="num_text">{{dataList.shares_amount}}</div>
      <div class="bottom_p">
        <div class="numReact">增值方式：{{dataList.apply_type==1?'分享式':(dataList.apply_type==2?'置换式':'增值式')}} 1:{{dataList.shares_cardinal}}</div>
        <div>
          <div>运费：{{shares_apply_shipping_fee}}</div>
          <div v-if="dataList.apply_type==3" style="margin-top:5px">增值费：{{dataList.cash_amount}}</div>
        </div>
      </div>
    </div>

    <div class="content_p" v-if="address" @click="selectBtn">
      <div style="display: flex;justify-content: space-between;">
        <div>姓名：{{address.receiver_name}}</div>
        <div>联系方式：{{address.receiver_mobile}}</div>
      </div>
      <div class="address_item_p">收件地址：{{address.receiver_address}}</div>
    </div>
    <div class="content_p1" @click="selectBtn" v-else>
      <div>请选择收货地址</div>
    </div>
    <!--<div class="input_list" style="margin-left:20px" v-if="is_referrer==1">
      <div>邀请人：</div>
      <input v-model="inviter" placeholder-class="" placeholder="请输入邀请人" />
    </div> -->
    <div v-if="is_referrer==1">
      <div class="style_size" v-if="presentData">推荐人：<span class="rerrm_member">{{presentData.referrer_phone}}</span><img @click="addBtn" class="referrerImg" src="../../assets/Slice120.png" /></div>
      <div class="style_size" v-else-if="is_peopleShow">推荐人：<span class="rerrm_member">不选择推荐人</span><img @click="addBtn" class="referrerImg" src="../../assets/Slice120.png" /></div>
      <div class="style_size" v-else>推荐人：<span class="rerrm_member" @click="addBtn">点击选择推荐人</span></div>
    </div>

    <!-- 支付方式 -->
    <pay-status @radioBtn="radioBtn"></pay-status>

    <!-- 线下支付 -->
    <online-pay @uploadImg="uploadImg" :payStatus="payStatus" v-if="payStatus==1"></online-pay>

    <!-- 支付金額 -->
    <div class="priceText">实际支付金额：￥<span>{{dataList.pay_amount}}</span></div>

    <!-- 信息備注 -->
    <div class="text_box" v-if="false">
      <div>信息备注：</div>
      <textarea v-model="message"></textarea>
    </div>

    <div class="submitBtn_p" @click="payBtnComfim">提交</div>

    <!-- 选择推荐人 -->
    <div class="pop_present" v-if="presentShow">
      <div class="present_box">
        <div style="text-align: center;font-size:16px;margin-bottom:20px;">选择推荐人<span class="cloneBtn" @click="presentShow=false">X</span></div>
        <div style="color:#3078FF;border-bottom:1px solid #eee;padding-bottom:10px">当前推荐人：{{referrerMember}}</div>
        <div class="selectStatus1">
          <van-radio-group class="selectList" @change="selectBtn1" v-model="radioValue" icon-size="18px">
            <van-radio class="radioClass" style="margin-top:20px" v-for="(item,index) in referrerDeatil" :key="index" checked-color="#4EB84A" :name="index">{{item.referrer_name}}<span style="margin-left:20px">{{item.referrer_phone}}</span><span class="confim" v-if="item.is_preference==1">{{item.is_preference==1?'默认':''}}</span></van-radio>
          </van-radio-group>
        </div>
        <div class="item_rerr">
          <div style="border-right:1px solid #eee" @click="peopleBtn">不选择推荐人</div>
          <div style="color:#FF2128" @click="comfimBtn">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import payStatus from "../../components/expenditure/payStatus";
import onlinePay from "../../components/expenditure/onlinePay";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
import { Toast } from "vant";
import { Dialog } from "vant";
import {
  applyItem_api,
  getConfig_api,
  applyPut_api,
  referrerDeatil_api
} from "@/api/deal";
export default {
  data() {
    return {
      payStatus: 1,
      bgcShow: true,
      queryList: null,
      dataList: {},
      shares_apply_shipping_fee: null,
      address: null,
      img: null,
      message: null,
      payShow: true,
      inviter: null,
      is_referrer: "",
      referrerDeatil: [],
      referrerMember: "",
      presentShow: false,
      presentData: null,
      cloneShow: false,
      radioValue: 1,
      is_peopleShow:false,
      presentIndex:-1
    };
  },
  components: {
    cloneTab,
    payStatus,
    onlinePay
  },
  created() {
    document.title = "支付页面";
    // 获取默认地址
    if (JSON.parse(localStorage.getItem("address"))) {
      this.address = JSON.parse(localStorage.getItem("address"));
      console.log(this.address);
    }
    this.queryList = this.$route.query;
    this.getDetails();
    // 获取配置
    this.getConfig();
    this.getreferrr();
  },
  methods: {
    selectBtn1(row) {
      console.log(row);
      this.presentIndex = row;
    },
    peopleBtn(){
        this.presentShow=false;
        this.is_peopleShow = true;
        this.presentData=""
    },
    comfimBtn() {
      if(this.presentIndex!=-1){
        this.presentData = this.referrerDeatil[this.presentIndex];
      }
      this.presentShow = false;
      
      this.is_peopleShow = false
    },
    addBtn() {
      this.presentShow = true;
    },
    getreferrr() {
      referrerDeatil_api({ page: 1, limit: 10 }).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.referrerDeatil = res.data;
          this.referrerDeatil.forEach((e, index) => {
            if (e.is_preference == 1) {
              this.referrerMember = e.referrer_name;
              this.radioValue = index;
              this.presentIndex = index;
            }
          });
        }
      });
    },
    radioBtn(index) {
      this.payStatus = index;
    },
    getDetails() {
      applyItem_api(this.queryList.id).then(res => {
        if (res.code == 0) {
          console.log(res, "12");
          this.dataList = res.data;
        }
      });
    },
    getConfig() {
      let data = {
        config: ["shares_apply_shipping_fee", "appreciation_config"]
      };
      getConfig_api(data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.shares_apply_shipping_fee = res.data.shares_apply_shipping_fee;
          this.is_referrer = JSON.parse(
            res.data.appreciation_config
          ).is_appreciation;
          console.log(res.data.shares_apply_shipping_fee);
        }
      });
    },
    selectBtn() {
      this.$router.push("../address");
    },
    uploadImg(data) {
      this.img = data;
    },
    payBtnComfim() {
      if (!this.presentData&&this.is_referrer==1) {
        Dialog.confirm({
          title: "温馨提示",
          message: "确认不使用推荐人直接下单？",
          confirmButtonText: "去使用",
          cancelButtonText: "直接购买"
        })
          .then(() => {
            this.presentShow = true
            
          })
          .catch(() => {
            // on cancel
            console.log(2);
            this.payBtn();
          });
      } else {
        this.payBtn();
      }
    },
    async payBtn() {
      let data = {};
      if (this.payStatus == 1) {
        if (!this.img) {
          this.$toast("请上传凭证");
          return false;
        }
        data.pay_info = {
          message: this.message,
          img: this.img
        };
        data.pay_type = this.payStatus;
        data.audit_shipments = {
          receiver_name: this.address.receiver_name,
          receiver_mobile: this.address.receiver_mobile,
          receiver_address: this.address.receiver_address
        };
        if (this.is_referrer == 1) {
          data.referrer_phone = this.presentData
            ? this.presentData.referrer_phone
            : "";
          if (!data.referrer_phone) {
            delete data.referrer_phone;
          } else {
            console.log(123);
          }
        }
        applyPut_api(this.queryList.id, data).then(res => {
          if (res.code == 0) {
            this.$router.go(-1);
          } else {
            this.$toast(res.error);
          }
        });
      } else {
        data.pay_info = {
          message: this.message
        };
        data.pay_type = this.payStatus;
        data.audit_shipments = {
          receiver_name: this.address.receiver_name,
          receiver_mobile: this.address.receiver_mobile,
          receiver_address: this.address.receiver_address
        };
        Toast.loading({
          message: "加载中...",
          forbidClick: true
        });
        let res1 = await apiCommon.getConfig();
        console.log(res1);
        let tableData = res1.data;
        wx.config(tableData);
        if (res1.code == 0) {
          if (this.payShow == false) {
            return false;
          }
          this.payShow = false;
          let res = await apiCommon.putSharePay_api(
            this.dataList.apply_id,
            data
          );
          this.payShow = true;
          let that = this;
          if (res.code == 0) {
            wx.chooseWXPay({
              timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
              package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: res.data.pay_info.paySign, // 支付签名
              success(res) {
                if (res.errMsg == "chooseWXPay:ok") {
                  that.$toast("支付成功");
                  that.getDetails(that.tableData.apply_id);
                } else {
                  alert(res.errMsg);
                  that.$toast("支付失败", "middle");
                }
              },
              cancel: function(res) {
                that.$toast("支付取消", "middle", res);
              }
            });
          }
        }
      }
    }
  }
};
</script>
<style>
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 30px;
}
.van-icon {
  font-size: 30px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.container_pay {
  padding-top: 40px;
  padding-bottom: 150px;
  background-image: fff;
  background-size: 100% 100%;
  padding-bottom: 100px;
}
.input_list {
  width: 600px;
  height: 90px;
  line-height: 90px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.3);
  color: #563b15;
  font-size: 32px;
  display: flex;
  padding-left: 22px;
  box-sizing: border-box;
  margin: 20px 0;
}
input {
  border: none;
  background: transparent;
  height: 45px;
  margin-top: 20px;
  width: 400px;
}
.content_p {
  width: 700px;
  height: 220px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(46, 46, 48, 0.5);
  margin: 40px auto;
  padding: 38px 48px 0;
  font-size: 30px;
  color: #2e2e30;
  box-sizing: border-box;
}
.content_p1 {
  width: 700px;
  height: 220px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(46, 46, 48, 0.5);
  margin: 40px auto;
  padding: 38px 48px 0;
  font-size: 30px;
  color: #2e2e30;
  box-sizing: border-box;
  text-align: center;
  line-height: 220px;
}
.title_p {
  font-size: 30px;
  color: #eed09c;
  letter-spacing: 2px;
  line-height: 40px;
}
.address_p {
  margin-top: 30px;
  margin-left: 35px;
}
.address_title {
  font-size: 30px;
  color: #fff;
  margin-left: 10px;
}
.address_box {
  width: 680px;
  height: 170px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(204, 175, 124, 0.6);
  line-height: 170px;
  text-align: center;
  font-size: 30px;
  color: #e3c084;
  margin: 36px 0;
}
.wxPay,
.pricePay {
  display: flex;
  font-size: 30px;
  color: #e3c084;
  line-height: 50px;
}
.wxPay img {
  width: 50px;
  height: 46px;
  margin: 0 30px;
}
.pricePay img {
  width: 64px;
  height: 64px;
  margin: 0 30px;
}
.priceText {
  font-size: 30px;

  text-align: right;
  margin-right: 55px;
}
.priceText span {
  font-size: 50px;
}
.text_box {
  margin-top: 18px;
  font-size: 30px;
  color: #fff;
  margin-left: 50px;
  margin-bottom: 100px;
}
.text_box div {
  margin-bottom: 30px;
}
.text_box textarea {
  background-color: transparent;
  width: 640px;
  height: 276px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(204, 175, 124, 0.5);
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
  color: #000;
}
.submitBtn_p {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 489px;
  height: 80px;
  background: #d6b06f;
  box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.3);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  text-align: center;
  line-height: 80px;
  font-size: 35px;
  color: #fff;
}
.header_box {
  width: 700px;
  height: 320px;
  background: #2e2e30;
  border-radius: 15px 15px 15px 15px;
  opacity: 1;
  margin: 30px auto 0;
  padding: 50px 30px 30px;
  box-sizing: border-box;
  font-size: 30px;
  color: #fff;
}
.bottom_p {
  display: flex;
  justify-content: space-between;
}
.num_text {
  font-size: 80px;
  margin-top: 30px;
}
.numReact {
  margin-top: 40px;
}

.address_item_p {
  margin-top: 20px;
}

.style_size {
  font-size: 25px;
  color: #2e2e30;
  margin: 45px 0 37px 31px;
  font-weight: 800;
}
.style_size span {
  font-size: 50px;
  font-weight: 800;
  margin-left: 45px;
}

.style_size .rerrm_member {
  color: skyblue;
  font-size: 25px;
  display: inline-block;
}
.pop_present {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.present_box {
  width: 600px;
  height: 700px;
  background: #ffffff;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin: 200px auto 0;
  border-radius: 10px;
  font-size: 25px;
  padding: 30px 30px;
  box-sizing: border-box;
}
.selectStatus1 {
  margin-top: 15px;
  overflow: scroll;
  height: 400px;
}
.radioClass {
  width: 543px;
  height: 80px;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 1px solid #dedede;
  padding: 0 15px;
  box-sizing: border-box;
}
.item_rerr {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  border-top: 1px solid #eee;
  margin-top: 0px;
}
.item_rerr div {
  flex: 1;
  text-align: center;
  line-height: 100px;
}
.confim {
  display: inline-block;
  width: 91px;
  height: 41px;
  background: #4eb84a;
  opacity: 1;
  color: #fff;
  text-align: center;
  line-height: 41px;
  margin-left: 15px;
  border-radius: 20px;
}
.cloneBtn {
  margin-left: 180px;
  font-size: 25px;
}
.referrerImg {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}

.clone_box {
}

.van-dialog {
  width: 500px;
  height: 200px;
  font-size: 30px;
}

.van-dialog__message--has-title {
  font-size: 28px;
  margin-top: 20px;
}
.van-dialog__confirm,
.van-dialog__cancel {
  font-size: 30px !important;
}
</style>
